// import { getAnalytics } from "firebase/analytics";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCs-4g4YW5Cuj4iCNSVcQXiiQejp_FEX1g",
    authDomain: "tudipott.firebaseapp.com",
    databaseURL: "https://tudipott-default-rtdb.firebaseio.com",
    projectId: "tudipott",
    storageBucket: "tudipott.appspot.com",
    messagingSenderId: "1010762215936",
    appId: "1:1010762215936:web:2aea5b98bdc3e408525ee5",
    measurementId: "G-GV6KWJ69HG"
  };
  
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
//   const analytics = getAnalytics(app);

// Initialize Realtime Database and get a reference to the service
// Initialize firebase database and get the reference of firebase database object.
export default firebase;
// export const firebaseDB = firebase.database();
export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider()
/*


export const signInWithGoogle = () => {
    console.log("i am in signin")
    auth.signInWithPopup(googleProvider).then((res) => {
        console.log(res.user)
        const userAuth = res.user;
        const dispatch = useDispatch()
        dispatch(login({
            uid: userAuth.uid,
            email: userAuth.email,
            displayName: userAuth.displayName,
            photoURL: userAuth.photoURL,
            phoneNumer: userAuth.phoneNumber 
          }))
  }).catch((error) => {
    console.log(error.message)
    alert(error.message);
  })
}

export const logOut = () => {
    auth.signOut().then(()=> {
      console.log('logged out')
      const dispatch = useDispatch()
      dispatch(logout());
    }).catch((error) => {
      console.log(error.message)
    })
  }
  */
import React, { useEffect, useState } from "react";
import './LoginScreen.css';
import SignUpScreen from "./SignUpScreen";
import home_logo from '../home_logo.png';
import { auth, googleProvider } from '../services/firebase';
import { useDispatch } from 'react-redux';
import { login, logout } from '../features/userSlice';

function LoginScreen() {
    const [signIn, setSignIn] = useState(false);
    const dispatch = useDispatch()
    const signInWithGoogle = () => {
        // googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        googleProvider.setCustomParameters({prompt:'select_account'});
        auth.signInWithPopup(googleProvider).then((res) => {
            console.log(res.user)
            const userAuth = res.user;
            dispatch(login({
                uid: userAuth.uid,
                email: userAuth.email,
                displayName: userAuth.displayName,
                photoURL: userAuth.photoURL,
                phoneNumer: userAuth.phoneNumber 
              }))
      }).catch((error) => {
        console.log(error.message)
        alert(error.message);
      })
    }
    return (
        <div className="loginScreen">
            <div className="loginScreen__background">
                <img className="loginScreen__logo"
                    src={home_logo}
                ></img>
                <button className="loginScreen_button"
                    onClick={signInWithGoogle}
                >Login with Gmail</button>
                <div className="loginScreen__gradient"></div>
            </div>
            <div className="loginScreen__body">
                {signIn ? (
                    <SignUpScreen />
                ) : (
                    <>
                        <h1>Timeless Classics, Limitless Streaming.</h1>
                        <h2>Your Gateway to Vintage Entertainment.</h2>
                        <h3>Ready to watch? login to start</h3>
                        <div className="loginScreen__input">
                                    <button className="loginScreen__getStarted" onClick={signInWithGoogle}>
                                        Login with Gmail
                                    </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default LoginScreen
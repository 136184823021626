import React, { useEffect, useRef, useState } from "react";
import './VideoScreen.css';
import ReactPlayer from 'react-player'
import backLogo from '../back.png';
import {
    useParams
} from "react-router-dom";
import { selectPrograms, storePrograms } from '../features/programSlice';
import firebase from '../services/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../features/userSlice';
import { selectBookmarks } from '../features/bookmarkSlice';

function VideoScreen() {
    const { id } = useParams();
    const programs = useSelector(selectPrograms);
    const [program, setProgram] = useState({});
    const dispatch = useDispatch()
    const playerRef = useRef();
    const user = useSelector(selectUser);
    const bookmarks = useSelector(selectBookmarks);
    
    // console.log("user------", user);

    const handlePause = () => {
        console.log('I am in handle pause ', playerRef.current.getDuration(), playerRef.current.getCurrentTime(), user);
        saveBookmark();
    }

    const handleReady = () => {
        console.log("I am in handleready")
        if(playerRef.current) {
            const program = bookmarks ? bookmarks.find(bookmark => bookmark.id == id) : undefined
            let seek = 0;
            if(program) {
                console.log('Inside if');
                seek = (program.bookmark / 1000)
            }
            playerRef.current.seekTo(seek, 'seconds');
        }
    }

    const saveBookmark = () => {
        console.log("i am in saveBookmark")
        const dbBookmarkReference = firebase.database().ref('/Bookmark/' + user.uid + '/' + id);
        dbBookmarkReference.set({
            bookmark: (playerRef.current.getCurrentTime() * 1000),
            id: parseInt(id),
            totalDuration: (playerRef.current.getDuration() * 1000),
            time : firebase.database.ServerValue.TIMESTAMP
        })
    }

    const videoProgress = (state) => {
        // Save the current played time to state
        saveBookmark()
    }

    useEffect(() => {
        async function fetchData() {
            if (!programs) {
                const dbRefProgram = firebase.database().ref('/OnDemandProgram');
                dbRefProgram.once('value').then((snapshot) => {
                    console.log("I akm in")
                    if (snapshot.exists()) {
                        console.log("OnDemandProgram--", snapshot.val());
                        dispatch(storePrograms(snapshot.val()));
                        const program = programs.filter(program => program.id == id);
                        console.log('program single ', program);
                        setProgram(program[0]);
                        return program;
                    } else {
                        console.log("No data available");
                    }
                }).catch((error) => {
                    console.log("firebase", error);
                });
            } else {
                const program = programs.filter(program => program.id == id);
                console.log('program single ', program);
                setProgram(program[0]);
                return program;
            }
        }
        fetchData()
    }, [programs])

    

    return (
        // Render a YouTube video player

        <div className="videoscreen_container">
            {program ? <>
                <ReactPlayer
                    ref={playerRef}
                    width='100%'
                    height='100vh'
                    controls={true}
                    playing={true}
                    // Disable download button
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                    // Disable right click
                    onContextMenu={e => e.preventDefault()}
                    light={program.banner}
                    url={program.url}
                    onPlay = {() => {handleReady()}}
                    progressInterval={10000}
                    onProgress={(state) => {
                        // console.log("OnProgress", state);
                        videoProgress(state);
                    }}
                    onPause={handlePause}
                     />
            </> : null}

            {/* <div class="overlay">
                <a href="#" class="icon" title="back button">
                    <i class="fa fa-user"></i>
                    <img src={backLogo}></img>
                    <text>"Movie Name"</text>
                    <KeyboardBackspaceOutlinedIcon />
                </a>
            </div> */}
        </div>
    )
}

export default VideoScreen;
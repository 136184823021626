import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import genresReducer from '../features/genresSlice';
import programsReducer from '../features/programSlice';
import bookmarksReducer from '../features/bookmarkSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    genres: genresReducer,
    programs: programsReducer,
    bookmarks: bookmarksReducer,
  },
});

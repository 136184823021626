import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { login } from "../features/userSlice";
import './SignUpScreen.css'

function SignUpScreen() {
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const dispatch = useDispatch();
    const register = (e) => {
        e.preventDefault();
        console.log(emailRef.current.value, passwordRef.current.value);
        if(emailRef.current.value && passwordRef.current.value) {
            dispatch(login({
                email: emailRef.current.value
            }))
        } else {
            alert("Email and Password cannot be blank");
        }
    }

    const signIn = (e) => {
        e.preventDefault();
        console.log(!emailRef.current.value, passwordRef.current.value);
        if(emailRef.current.value && passwordRef.current.value) {
            dispatch(login({
                email: emailRef.current.value
            }))
        } else {
            alert("Email and Password cannot be blank");
        }
    }
    return (
        <div className="signUpScreen">
            <form>
                <h1>Sign In</h1>
                <input ref={emailRef} placeholder="Email" type="email"></input>
                <input ref={passwordRef} placeholder="Password" type="password"></input>
                <button type="submit" onClick={signIn}>Sign In</button>
                <h4>
                    <span className="signUpScreen_grey">New to Tudip Studio? </span>
                    <span className="signUpScreen_link" onClick={register}>Sign Up now.</span>
                </h4>
            </form>
        </div>
    )
}

export default SignUpScreen;

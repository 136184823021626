import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Nav.css';
import home_logo from '../home_logo.png';
import ts_avatar from '../tudip_studio_avatar.png';

function Nav() {

    const [show, handleShow] = useState(false);
    const navigate = useNavigate();

    const transitionNavBar = () => {
        if(window.scrollY > 100) {
            handleShow(true)
        } else {
            handleShow(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", transitionNavBar);
        return () => window.removeEventListener("scroll", transitionNavBar);
    }, []);

  return (
    <div className={`nav ${ show && "nav__black"}`}>
        <div className='nav__contents'>
        <img 
        onClick={() => {navigate('/')}}
        className='nav__logo'
        src={home_logo} 
        alt=''></img>

        <img 
        onClick={() => {navigate("/profile")}}
        className='nav__avatar'
        src={ts_avatar} alt=''></img>
        </div>
       
    </div>
  );
}

export default Nav;
import React, { useEffect, useState } from "react";
import "./App.css";
import HomeScreen from "./screens/HomeScreen";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginScreen from "./screens/LoginScreen";
import { useSelector, useDispatch } from "react-redux";
import { login, logout, selectUser } from "./features/userSlice";
import ProfileScreen from "./screens/ProfileScreen";
import VideoScreen from "./screens/VideoScreen";
import { auth } from "./services/firebase";
import Loader from "./components/Loader";

function App() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        dispatch(
          login({
            uid: userAuth.uid,
            email: userAuth.email,
            displayName: userAuth.displayName,
            photoURL: userAuth.photoURL,
            phoneNumer: userAuth.phoneNumber,
          })
        );
      } else {
        dispatch(logout());
      }
      setLoading(false);
    });
    return unsubscribe;
  }, [dispatch]);
  return (
    <div className="app">
      {loading ? (
        <Loader />
      ) : (
        <Router>
          {user ? (
            <Routes>
              <Route path="/" element={<HomeScreen />}></Route>
              <Route path="/profile" element={<ProfileScreen />}></Route>
              <Route path="/player/:id" element={<VideoScreen />}></Route>
            </Routes>
          ) : (
            <LoginScreen />
          )}
        </Router>
      )}
    </div>
  );
}

export default App;

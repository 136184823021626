import axios from './axios';
import React, { useEffect, useState } from 'react';
import './Banner.css';
import { selectPrograms } from './features/programSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

function Banner() {
  const [movie, setMovie] = useState([]);
  const programs = useSelector(selectPrograms);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setMovie(programs[
        Math.floor(Math.random() * programs.length-1)
      ])
      return {};
    }
    fetchData();
  }, [])

  const handleClick = (movie) => {
    console.log('movie ', movie);
    navigate(`/player/${movie.id}`);
}

    function truncate(string, number) {
        return string?.length > number ? string.substr(0, number-1) + '...' : string;
    }
  return (
    <>
    { programs && programs.length > 0 ? <>
      <header className="banner" style={{
        backgroundSize: "cover",
        backgroundImage: `url("${movie?.banner}")`,
        backgroundPosition: "center center"}}>
      <div className='banner__contents'>
          <h1 className='banner__title'>{movie?.title || movie?.name || movie?.original_name}</h1>
          <div className='banner__buttons'>
              <button className='banner__button' onClick={() => handleClick(movie)}>Play</button>
              {/* <button className='banner__button'>My List</button> */}
          </div>
          <h1 className='banner__discription'>
              {truncate(movie?.description, 250)}
          </h1>
          <h1 className='banner__discription'>
            {movie && movie.time ? <>Duration: {movie?.time} <br></br>
            Release: {movie?.release} <br></br>
            Rating: {movie?.rateStar}{' | '}{movie?.rating} <br></br>
            Directors: {movie?.directors} <br></br>
            Stars: {movie?.stars}
            </> : null}
          </h1>
      </div>
      <div className='banner--fadeBottom'></div>
    </header>
    </> : null }
    </>
    
  );
}

export default Banner;

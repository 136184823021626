import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser } from "../features/userSlice";
import Nav from "../components/Nav";
import "./ProfileScreen.css";
import { auth } from "../services/firebase";
import { useNavigate } from "react-router-dom";
import ts_avatar from "../tudip_studio_avatar.png";

function ProfileScreen() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googleLogOut = () => {
    auth
      .signOut()
      .then(() => {
        console.log("logged out");
        dispatch(logout());
        navigate("/");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div className="profileScreen">
      <Nav />
      <div className="main_parent_div">
        <h1 className="heading_text">Edit Profile</h1>
        <div className="from_container">
          <div className="img_container">
            <img src={ts_avatar} alt="" className="img" />
          </div>
          <div>
            <div className="form_inner_container ">
              <h2 className="text">Name: {user.displayName}</h2>
              <div className="border" />
              <h2 className="text">Email: {user.email}</h2>
              <div className="border" />

              {user.phoneNumber ? (
                <>
                  <h2 className="text">{user.phoneNumber}</h2>
                  <div className="border" />
                </>
              ) : null}
            </div>
            <div className="buttonContainer">
              <button className="button_style" onClick={googleLogOut}>
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileScreen;

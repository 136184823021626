import React, { useEffect } from "react";
import Banner from "../Banner";
import "./HomeScreen.css";
import Nav from "../components/Nav";
import Row from "../Row";
import firebase from "../services/firebase";
import { useDispatch, useSelector } from "react-redux";
import { storeGenres, selectGenres } from "../features/genresSlice";
import { storePrograms, selectPrograms } from "../features/programSlice";
import { storeBookmarks, selectBookmarks } from "../features/bookmarkSlice";
import { selectUser } from "../features/userSlice";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader";

function HomeScreen() {
  const genres = useSelector(selectGenres);
  const programs = useSelector(selectPrograms);
  const bookmarks = useSelector(selectBookmarks);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  console.log("user------", user);

  const fetchBookmarkPrograms = () => {
    const dbRefProgram = firebase.database().ref("/Bookmark/" + user.uid);
    dbRefProgram.on("value", (snapshot) => {
      console.log("I akm in");
      if (snapshot.exists()) {
        console.log("Bookmark Program--", snapshot.val());
        const unSortedBookmark = snapshot.val();
        const sortedBookmark = Object.values(unSortedBookmark).sort(
          (a, b) => b.time - a.time
        );
        dispatch(storeBookmarks(sortedBookmark));
      } else {
        console.log("No data available fetchBookmarkPrograms");
        dispatch(storeBookmarks([]));
      }
    });
  };

  useEffect(() => {
    const fetchPrograms = () => {
      const dbRefProgram = firebase.database().ref("/OnDemandProgram");
      dbRefProgram
        .once("value")
        .then((snapshot) => {
          console.log("I akm in");
          if (snapshot.exists()) {
            console.log("OnDemandProgram--", snapshot.val());
            dispatch(storePrograms(snapshot.val()));
          } else {
            console.log("No data available fetchPrograms");
          }
        })
        .catch((error) => {
          console.log("firebase", error);
        });
    };
    const fetchGeners = () => {
      const dbRef = firebase.database().ref("/OnDemandType");
      dbRef
        .once("value")
        .then((snapshot) => {
          console.log("I akm in");
          if (snapshot.exists()) {
            console.log("OnDemandType--", snapshot.val());
            dispatch(storeGenres(snapshot.val()));
          } else {
            console.log("No data available fetchGeners");
          }
        })
        .catch((error) => {
          console.log("firebase", error);
        });
    };

    fetchGeners();
    fetchPrograms();
    fetchBookmarkPrograms();
  }, []);

  const location = useLocation();

  useEffect(() => {
    console.log("I am in location useEffect");
    fetchBookmarkPrograms();
  }, [location.pathname]);

  return (
    <div className="homeScreen">
      {genres &&
      genres.length > 0 &&
      programs &&
      programs.length > 0 &&
      bookmarks ? (
        <>
          <Nav />

          <Banner />

          {bookmarks ? (
            <Row
              title={"Continue Watching"}
              fetchURL={"https://tudip.com"}
              isLargeRow
            ></Row>
          ) : null}
          {programs &&
            genres.map((item, index) => {
              return (
                <Row
                  title={item.name}
                  fetchURL={
                    "https://api.themoviedb.org/3/movie/popular?language=en-US&page=1"
                  }
                />
              );
            })}
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </div>
  );
}

export default HomeScreen;

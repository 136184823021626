import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  programs: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   'counter/fetchCount',
//   async (amount) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const programSlice = createSlice({
  name: 'programs',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    storePrograms: (state, action) => {
      console.log("payload",action.payload);
      state.programs = action.payload;
      console.log("state.programs", state.programs);
    },
  },

});

export const { storePrograms } = programSlice.actions;

export const selectPrograms = (state) => state.programs.programs;

export default programSlice.reducer;

import React, { useEffect, useState } from "react";
import './Row.css'
import YouTube from "react-youtube";
import movieTrailer from "movie-trailer";
import { useNavigate } from "react-router-dom";
import { selectPrograms } from './features/programSlice';
import { selectBookmarks } from "./features/bookmarkSlice";
import { useSelector } from 'react-redux';

function Row({title, fetchURL, isLargeRow}) {
    const programs = useSelector(selectPrograms);
    const bookmarks = useSelector(selectBookmarks);
    const [filteredPrograms, setFilteredPrograms] = useState([]);
    const [trailerURL, setTrailerURL] = useState("");
    const navigate = useNavigate();
    const opts = {
        height: "390",
        width: "100%",
        playerVars: {
            autoPlay: 1,
        },
    }
    const fetchBookmarks = () => {
       
        let continuePrograms = [];
        bookmarks.map((bookmark) => {
            const matches = programs.filter(program => program.id === parseInt(bookmark.id));
            if (matches.length > 0) {
                console.log("Inside matches if ", bookmark);
                continuePrograms = continuePrograms.concat(matches);
            }
            console.log('continuePrograms ', continuePrograms);
            setFilteredPrograms(continuePrograms);;
          }); 
    }
    useEffect(() => {
        async function fetchData() {
              console.log('programs----*******---', programs);
              const filtered = programs.filter(program => program.genere.includes(title));
              console.log('filtered ', filtered, title);
              setFilteredPrograms(filtered);
            return filtered;
        }
        console.log("isLargeRow ", isLargeRow);
        if(isLargeRow) {
            console.log("Inside isLargeRow");
            fetchBookmarks();
            // console.log("Inside Continue Program", continuePrograms);
            // setFilteredPrograms(continuePrograms);
        } else {
            fetchData()
        }
    }, [fetchURL])

    const handleClick = (movie) => {
        console.log('movie ', movie);
        navigate(`/player/${movie.id}`);
        // if(trailerURL) {
        //     setTrailerURL("")
        // } else {
        //     movieTrailer(movie?.title || movie?.name || movie?.original_name || "")
        //     .then((url) =>{
        //         const URLParams = new URLSearchParams(new URL(url).search)
        //         setTrailerURL(URLParams.get('v'));
        //     })
        //     .catch(err => console.log("err", err));
        // }
    }
    return(
        <div className="row">
            {filteredPrograms.length > 0 ? <>
                <h2>{title}</h2>
            <div className="row__posters">
            {filteredPrograms.map((movie) => 
          
            (
                <>
                <img className={`row__poster ${isLargeRow && "row__posterLarge"}`}
                key={movie.id}
                onClick={() => handleClick(movie)}
                src={`${
                    isLargeRow ? movie.image : movie.image
                }`} alt={movie.title}>
                </img>
                {/* {trailerURL && <video className="overlay" src="https://www.youtube.com/watch?v=HW5roUF2RLg"></video>} */}
                {/* {trailerURL && <YouTube videoId={trailerURL} opts={opts}/>} */}
                </>
            )
            )}
            </div>
           {trailerURL && <YouTube videoId={trailerURL} opts={opts}/>}
            </> : null}
            
        </div>
    )
}

export default Row;
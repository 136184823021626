import React from "react";
import "./Loader.css";
import home_logo from "../home_logo.png";

function Loader() {
  return (
    <div className="loader-container">
      <img src={home_logo} alt="Loading..." className="loader-logo" />;
    </div>
  );
}
export default Loader;
